import { request, gql } from 'graphql-request'
import { ChainId, Fetcher, Route, Token } from '@netswap/sdk';
import { Fetcher as FetcherSpirit, Token as TokenSpirit } from '@spiritswap/sdk';
import { Configuration } from './config';
import { ContractName, TokenStat, AllocationTime, LotteryResponse, LotteryStatus, LotteryUserGraphEntity, LotteryTicket, LPStat, Bank, PoolStats, PShareSwapperStat, UserRound, LotteriesWhere, UserLotteriesWhere, LotteryTicketClaimData, RoundDataAndUserTickets, LotteryRoundGraphEntity } from './types';
import { BigNumber, Contract, ethers, EventFilter } from 'ethers';
import { decimalToBalance} from './ether-utils';
import { TransactionResponse } from '@ethersproject/providers';
import ERC20 from './ERC20';
import { getFullDisplayBalance, getDisplayBalance } from '../utils/formatBalance';
import { getDefaultProvider } from '../utils/provider';
import IUniswapV2PairABI from './IUniswapV2Pair.abi.json';
import config, { bankDefinitions } from '../config';
import moment from 'moment';
import { parseUnits } from 'ethers/lib/utils';
import { METIS_TICKER, NETSWAP_ROUTER_ADDR, PEAK_TICKER } from '../utils/constants';
import axios from 'axios';
import { MAX_LOTTERIES_REQUEST_SIZE, MAX_USER_LOTTERIES_REQUEST_SIZE, GRAPH_API_LOTTERY, NUM_ROUNDS_TO_FETCH_FROM_NODES, TICKET_LIMIT_PER_REQUEST, NUM_ROUNDS_TO_CHECK_FOR_REWARDS } from './constants';
import ERC721 from './ERC721';
/**
 * An API module of Peak Finance contracts.
 * All contract-interacting domain logic should be defined in here.
 */

 const processViewLotterySuccessResponse = (response: { status: any; startTime: any; endTime: any; priceTicketInPeak: any; discountDivisor: any; treasuryFee: any; firstTicketId: any; lastTicketId: any; amountCollectedInPeak: any; finalNumber: any; peakPerBracket: any; countWinnersPerBracket: any; rewardsBreakdown: any; }, lotteryId: string): LotteryResponse => {
  const {
    status,
    startTime,
    endTime,
    priceTicketInPeak,
    discountDivisor,
    treasuryFee,
    firstTicketId,
    lastTicketId,
    amountCollectedInPeak,
    finalNumber,
    peakPerBracket,
    countWinnersPerBracket,
    rewardsBreakdown,
  } = response
    
  /*const serializedPeakPerBracket = peakPerBracket.map((peakInBracket: any) => ethersToSerializedBigNumber(peakInBracket))
  const serializedCountWinnersPerBracket = countWinnersPerBracket.map((winnersInBracket: any) =>
    ethersToSerializedBigNumber(winnersInBracket),
  )
  const serializedRewardsBreakdown = rewardsBreakdown.map((reward: any) => ethersToSerializedBigNumber(reward))*/

  return {
    isLoading: false,
    lotteryId,
    status: status?.toString(),
    startTime: startTime?.toString(),
    endTime: endTime?.toString(),
    priceTicketInPeak: priceTicketInPeak?.toString(),
    discountDivisor: discountDivisor?.toString(),
    treasuryFee: treasuryFee?.toString(),
    firstTicketId: firstTicketId?.toString(),
    lastTicketId: lastTicketId?.toString(),
    amountCollectedInPeak: amountCollectedInPeak,
    finalNumber,
    peakPerBracket: peakPerBracket?.toString(),
    countWinnersPerBracket: countWinnersPerBracket?.toString(),
    rewardsBreakdown: rewardsBreakdown?.toString(),
  }
}

const processRawTicketsResponse = (
  ticketsResponse: any
): LotteryTicket[] => {
  const [ticketIds, ticketNumbers, ticketStatuses] = ticketsResponse

  if (ticketIds?.length > 0) {
    return ticketIds.map((ticketId: { toString: () => any; }, index: string | number) => {
      return {
        id: ticketId.toString(),
        number: ticketNumbers[index].toString(),
        status: ticketStatuses[index],
      }
    })
  }
  return []
}

const processViewLotteryErrorResponse = (lotteryId: string): LotteryResponse => {
  return {
    isLoading: true,
    lotteryId,
    status: LotteryStatus.PENDING,
    startTime: '',
    endTime: '',
    priceTicketInPeak: '',
    discountDivisor: '',
    treasuryFee: '',
    firstTicketId: '',
    lastTicketId: '',
    amountCollectedInPeak: null,
    finalNumber: null,
    peakPerBracket: [],
    countWinnersPerBracket: [],
    rewardsBreakdown: [],
  }
}

export class PeakFinance {
  myAccount: string;
  provider: ethers.providers.Web3Provider;
  signer?: ethers.Signer;
  config: Configuration;
  contracts: { [name: string]: Contract };
  externalTokens: { [name: string]: ERC20 };
  masonryVersionOfUser?: string;

  PROMETIS_LP: Contract;
  PEAKWMETIS_LP: Contract;
  PEAK: ERC20;
  PSHARE: ERC20;
  PBOND: ERC20;
  PEAKING_DUCK: ERC721;
  WRAPPED_NFT: ERC721;
  METIS: ERC20;

  constructor(cfg: Configuration) {
    const { deployments, externalTokens } = cfg;
    const provider = getDefaultProvider();

    // loads contracts from deployments
    this.contracts = {};
    for (const [name, deployment] of Object.entries(deployments)) {
      this.contracts[name] = new Contract(deployment.address, deployment.abi, provider);
    }
    this.externalTokens = {};
    for (const [symbol, [address, decimal]] of Object.entries(externalTokens)) {
      this.externalTokens[symbol] = new ERC20(address, provider, symbol, decimal);
    }
    this.PEAK = new ERC20(deployments.peak.address, provider, 'PEAK');
    this.PSHARE = new ERC20(deployments.pShare.address, provider, 'PRO');
    this.PEAKING_DUCK = new ERC721(deployments.PeakingDuck.address, provider, 'Peaking Duck');
    this.WRAPPED_NFT = new ERC721(deployments.wNFT721.address, provider, 'Wrapped NFT 721');
    this.PBOND = new ERC20(deployments.pBond.address, provider, 'POND');
    this.METIS = this.externalTokens['WMETIS'];

    // Uniswap V2 Pair
    this.PEAKWMETIS_LP = new Contract(externalTokens['PEAK-METIS-LP'][0], IUniswapV2PairABI, provider);
    this.PROMETIS_LP = new Contract(externalTokens['PRO-METIS-LP'][0], IUniswapV2PairABI, provider);
    this.config = cfg;
    this.provider = provider;
  }

  /**
   * @param provider From an unlocked wallet. (e.g. Metamask)
   * @param account An address of unlocked wallet account.
   */
  unlockWallet(provider: any, account: string) {
    const newProvider = new ethers.providers.Web3Provider(provider, this.config.chainId);
    this.signer = newProvider.getSigner(0);
    this.myAccount = account;
    for (const [name, contract] of Object.entries(this.contracts)) {
      this.contracts[name] = contract.connect(this.signer);
    }
    const tokens = [this.PEAK, this.PSHARE, this.PBOND, this.PEAKING_DUCK, this.WRAPPED_NFT, ...Object.values(this.externalTokens)];
    for (const token of tokens) {
      token.connect(this.signer);
    }
    this.PEAKWMETIS_LP = this.PEAKWMETIS_LP.connect(this.signer);
    this.PROMETIS_LP = this.PROMETIS_LP.connect(this.signer);
    console.log(`🔓 Wallet is unlocked. Welcome, ${account}!`);
    this.fetchMasonryVersionOfUser()
      .then((version) => (this.masonryVersionOfUser = version))
      .catch((err) => {
        console.error(`Failed to fetch masonry version: ${err.stack}`);
        this.masonryVersionOfUser = 'latest';
      });
  }

  get isUnlocked(): boolean {
    return !!this.myAccount;
  }

  //=====================
  //Get PeakFinanceLottery Details
  //=====================
  
  async fetchLottery(lotteryId: string): Promise<LotteryResponse> {
    try {
      const {PeakFinanceLottery} = this.contracts;
      const lotteryData = await PeakFinanceLottery.viewLottery(lotteryId)
      return processViewLotterySuccessResponse(lotteryData, lotteryId)
    } catch (error) {
      return processViewLotteryErrorResponse(lotteryId)
    }
  }

  async fetchCurrentLotteryId(): Promise<Number> {
    const {PeakFinanceLottery} = this.contracts;
    return PeakFinanceLottery.currentLotteryId()
  }

  getRoundIdsArray(currentLotteryId: string): string[] {
    const currentIdAsInt = parseInt(currentLotteryId, 10)
    const roundIds = []
    for (let i = 0; i < NUM_ROUNDS_TO_FETCH_FROM_NODES; i++) {
      if(currentIdAsInt - i >= 0) {
        roundIds.push(currentIdAsInt - i)
      }
    }
    return roundIds.map((roundId) => roundId.toString())
  }
  
  hasRoundBeenClaimed(tickets: LotteryTicket[]): boolean {
    const claimedTickets = tickets.filter((ticket) => ticket.status)
    return claimedTickets.length > 0
  }

  async buyTickets(lotteryId: number, tickets: number[]): Promise<TransactionResponse>  {
    const { PeakFinanceLottery } = this.contracts;
    return await PeakFinanceLottery.buyTickets(lotteryId, tickets);
  }

  async claimTickets(lotteryId: number, tickets: number[], brackets: number[]): Promise<TransactionResponse>  {
    const { PeakFinanceLottery } = this.contracts;
    return await PeakFinanceLottery.claimTickets(lotteryId, tickets, brackets);
  }
  
  async fetchCurrentLotteryIdAndMaxBuy(): Promise<BigNumber> {
    const {PeakFinanceLottery} = this.contracts;
    return PeakFinanceLottery.maxNumberTicketsPerBuyOrClaim();
  }

  //Need to check this function
  async fetchMultipleLotteries (lotteryIds: string[]): Promise<LotteryResponse[]> {
    const {PeakFinanceLottery} = this.contracts;

    var multipleLotteryResponses = []; 

    for (var i = 0; i < lotteryIds.length; i++) {
      var data = await this.fetchLottery(lotteryIds[i]);
      var processedData = processViewLotterySuccessResponse (data, lotteryIds[i]);
      // note: we are adding a key prop here to allow react to uniquely identify each
      // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
      multipleLotteryResponses.push(processedData);
    }

    return multipleLotteryResponses;
  } 
  
  //========================
  // Get User Tickets Data==
  //========================  
  async viewUserInfoForLotteryId (
    account: string,
    lotteryId: string,
    cursor: number,
    perRequestLimit: number,
  ): Promise<LotteryTicket[]> {
    try {
      const {PeakFinanceLottery} = this.contracts;
      const data = await PeakFinanceLottery.viewUserInfoForLotteryId(account, lotteryId, cursor, perRequestLimit)
      return processRawTicketsResponse(data)
    } catch (error) {
      console.error('viewUserInfoForLotteryId', error)
      return null
    }
  }
  
  async fetchUserTicketsForOneRound(account: string, lotteryId: string): Promise<LotteryTicket[]> {
    let cursor = 0
    let numReturned = TICKET_LIMIT_PER_REQUEST
    const ticketData = []
  
    while (numReturned === TICKET_LIMIT_PER_REQUEST) {
      // eslint-disable-next-line no-await-in-loop
      const response = await this.viewUserInfoForLotteryId(account, lotteryId, cursor, TICKET_LIMIT_PER_REQUEST)
      cursor += TICKET_LIMIT_PER_REQUEST
      numReturned = response ? response.length : 0
      ticketData.push(...response)
    }
  
    return ticketData
  }
  
  async fetchUserTicketsForMultipleRounds (
    idsToCheck: string[],
    account: string,
  ): Promise<{ roundId: string; userTickets: LotteryTicket[] }[]> {
    const ticketsForMultipleRounds = []
    for (let i = 0; i < idsToCheck.length; i += 1) {
      const roundId = idsToCheck[i]
      // eslint-disable-next-line no-await-in-loop
      const ticketsForRound = await this.fetchUserTicketsForOneRound(account, roundId)
      ticketsForMultipleRounds.push({
        roundId,
        userTickets: ticketsForRound,
      })
    }
    return ticketsForMultipleRounds
  }

  //========================
  // Get User PeakFinanceLottery Data==
  //========================  
  
  applyNodeDataToUserGraphResponse = (
    userNodeData: { roundId: string; userTickets: LotteryTicket[] }[],
    userGraphData: UserRound[],
    lotteryNodeData: LotteryResponse[],
  ): UserRound[] => {
    //   If no graph rounds response - return node data
    if (userGraphData.length === 0) {
      return lotteryNodeData.map((nodeRound) => {
        const ticketDataForRound = userNodeData.find((roundTickets) => roundTickets.roundId === nodeRound.lotteryId)
        return {
          endTime: nodeRound.endTime,
          status: nodeRound.status,
          lotteryId: nodeRound.lotteryId.toString(),
          claimed: this.hasRoundBeenClaimed(ticketDataForRound.userTickets),
          totalTickets: `${ticketDataForRound.userTickets.length.toString()}`,
          tickets: ticketDataForRound.userTickets,
        }
      })
    }
  
    // Return the rounds with combined node + subgraph data, plus all remaining subgraph rounds.
    const nodeRoundsWithGraphData = userNodeData.map((userNodeRound) => {
      const userGraphRound = userGraphData.find(
        (graphResponseRound) => graphResponseRound.lotteryId === userNodeRound.roundId,
      )
      const nodeRoundData = lotteryNodeData.find((nodeRound) => nodeRound.lotteryId === userNodeRound.roundId)
      return {
        endTime: nodeRoundData.endTime,
        status: nodeRoundData.status,
        lotteryId: nodeRoundData.lotteryId.toString(),
        claimed: this.hasRoundBeenClaimed(userNodeRound.userTickets),
        totalTickets: userGraphRound?.totalTickets || userNodeRound.userTickets.length.toString(),
        tickets: userNodeRound.userTickets,
      }
    })
  
    // Return the rounds with combined data, plus all remaining subgraph rounds.
    const [lastCombinedDataRound] = nodeRoundsWithGraphData.slice(-1)
    const lastCombinedDataRoundIndex = userGraphData
      .map((graphRound) => graphRound?.lotteryId)
      .indexOf(lastCombinedDataRound?.lotteryId)
    const remainingSubgraphRounds = userGraphData ? userGraphData.splice(lastCombinedDataRoundIndex + 1) : []
    const mergedResponse = [...nodeRoundsWithGraphData, ...remainingSubgraphRounds]
    return mergedResponse
  }

  async getGraphLotteries(
    first = MAX_LOTTERIES_REQUEST_SIZE,
    skip = 0,
    where: LotteriesWhere = {},
  ): Promise<LotteryRoundGraphEntity[]> {
    try {
      const response = await request(
        GRAPH_API_LOTTERY,
        gql`
          query getLotteries($first: Int!, $skip: Int!, $where: Lottery_filter) {
            lotteries(first: $first, skip: $skip, where: $where, orderDirection: desc, orderBy: block) {
              id
              totalUsers
              totalTickets
              winningTickets
              status
              finalNumber
              startTime
              endTime
              ticketPrice
            }
          }
        `,
        { skip, first, where },
      )
      return response.lotteries
    } catch (error) {
      console.error(error)
      return []
    }
  }
  
  async getGraphLotteryUser (
    account: string,
    first = MAX_USER_LOTTERIES_REQUEST_SIZE,
    skip = 0,
    where: UserLotteriesWhere = {},
  ): Promise<LotteryUserGraphEntity> {
    //need to write this function
    let user
    const blankUser = {
      account,
      totalPeak: '',
      totalTickets: '',
      rounds: <any>[],
    }
    try {
      const response = await request(
        GRAPH_API_LOTTERY,
        gql`
          query getUserLotteries($account: ID!, $first: Int!, $skip: Int!, $where: Round_filter) {
            user(id: $account) {
              id
              totalTickets
              totalPeak
              rounds(first: $first, skip: $skip, where: $where, orderDirection: desc, orderBy: block) {
                id
                lottery {
                  id
                  endTime
                  status
                }
                claimed
                totalTickets
              }
            }
          }
        `,
        { account: account.toLowerCase(), first, skip, where }
      )
      const userRes = response.user
  
      // If no user returned - return blank user
      if (!userRes) {
        user = blankUser
      } else {
        user = {
          account: userRes.id,
          totalPeak: userRes.totalPeak,
          totalTickets: userRes.totalTickets,
          rounds: userRes.rounds.map((round: any) => {
            return {
              lotteryId: round?.lottery?.id,
              endTime: round?.lottery?.endTime,
              claimed: round?.claimed,
              totalTickets: round?.totalTickets,
              status: round?.lottery?.status.toLowerCase(),
            }
          }),
        }
      }
    } catch (error) {
      console.error(error)
      user = blankUser
    }
  
    return user
  }
  
  async getUserLotteryData (account: string, currentLotteryId: string): Promise<LotteryUserGraphEntity> {
    const idsForTicketsNodeCall = this.getRoundIdsArray(currentLotteryId)
    const roundDataAndUserTickets = await this.fetchUserTicketsForMultipleRounds(idsForTicketsNodeCall, account)
    const userRoundsNodeData = roundDataAndUserTickets.filter((round) => round.userTickets.length > 0)
    const idsForLotteriesNodeCall = userRoundsNodeData.map((round) => round.roundId)
    const lotteriesNodeData = await this.fetchMultipleLotteries(idsForLotteriesNodeCall)
    const graphResponse = await this.getGraphLotteryUser(account)
    const mergedRoundData = this.applyNodeDataToUserGraphResponse(userRoundsNodeData, graphResponse.rounds, lotteriesNodeData)
    const graphResponseWithNodeRounds = { ...graphResponse, rounds: mergedRoundData }
    return graphResponseWithNodeRounds
  }

  async getLotteriesData (currentLotteryId: string): Promise<LotteryRoundGraphEntity[]> {
    const idsForNodesCall = this.getRoundIdsArray(currentLotteryId)
    const nodeData = await this.fetchMultipleLotteries(idsForNodesCall)
    const graphResponse = await this.getGraphLotteries()
    const mergedData = this.applyNodeDataToLotteriesGraphResponse(nodeData, graphResponse)
    return mergedData
  }

  applyNodeDataToLotteriesGraphResponse (
    nodeData: LotteryResponse[],
    graphResponse: LotteryRoundGraphEntity[],
  ): LotteryRoundGraphEntity[] {
    //   If no graph response - return node data
    if (graphResponse.length === 0) {
      return nodeData.map((nodeRound: any) => {
        return {
          endTime: nodeRound.endTime,
          finalNumber: nodeRound.finalNumber.toString(),
          startTime: nodeRound.startTime,
          status: nodeRound.status,
          id: nodeRound.lotteryId.toString(),
          ticketPrice: nodeRound.priceTicketInPeak,
          totalTickets: '',
          totalUsers: '',
          winningTickets: '',
        }
      })
    }
  
    // Populate all nodeRound data with supplementary graphResponse round data when available
    const nodeRoundsWithGraphData = nodeData.map((nodeRoundData) => {
      const graphRoundData = graphResponse.find((graphResponseRound) => graphResponseRound.id === nodeRoundData.lotteryId)
      return {
        endTime: nodeRoundData.endTime,
        finalNumber: nodeRoundData.finalNumber.toString(),
        startTime: nodeRoundData.startTime,
        status: nodeRoundData.status,
        id: nodeRoundData.lotteryId,
        ticketPrice: graphRoundData?.ticketPrice,
        totalTickets: graphRoundData?.totalTickets,
        totalUsers: graphRoundData?.totalUsers,
        winningTickets: graphRoundData?.winningTickets,
      }
    })
  
    // Return the rounds with combined node + subgraph data, plus all remaining subgraph rounds.
    const [lastCombinedDataRound] = nodeRoundsWithGraphData.slice(-1)
    const lastCombinedDataRoundIndex = graphResponse
      .map((graphRound) => graphRound?.id)
      .indexOf(lastCombinedDataRound?.id)
  
    const remainingSubgraphRounds = graphResponse ? graphResponse.splice(lastCombinedDataRoundIndex + 1) : []
    const mergedResponse = [...nodeRoundsWithGraphData, ...remainingSubgraphRounds]
    return mergedResponse
  }

  //===================================================================
  //===================== REWARDS CLAIM AND UNCLAIM ===================
  //===================================================================
  
  async fetchRewardsByTicketId (lotteryId: string , ticketId: string, bracket: number): Promise<BigNumber> {
    const {PeakFinanceLottery} = this.contracts
    return PeakFinanceLottery.viewRewardsForTicketId(lotteryId, ticketId, bracket);
  }
  
  getRewardBracketByNumber (ticketNumber: string, finalNumber: string): number {
    // Winning numbers are evaluated right-to-left in the smart contract, so we reverse their order for validation here:
    // i.e. '1123456' should be evaluated as '6543211'
    const ticketNumAsArray = ticketNumber.split('').reverse()
    const winningNumsAsArray = finalNumber.split('').reverse()
    const matchingNumbers = []
  
    // The number at index 6 in all tickets is 1 and will always match, so finish at index 5
    for (let index = 0; index < ticketNumAsArray.length - 1; index++) {
      if (ticketNumAsArray[index] !== winningNumsAsArray[index]) {
        break
      }
      matchingNumbers.push(ticketNumAsArray[index])
    }
  
    // Reward brackets refer to indexes, 0 = 1 match, 5 = 6 matches. Deduct 1 from matchingNumbers' length to get the reward bracket
    const rewardBracket = matchingNumbers.length - 1
    return rewardBracket
  }
  
  async getWinningTickets (
    roundDataAndUserTickets: RoundDataAndUserTickets,
  ): Promise<LotteryTicketClaimData>  {
    const { roundId, userTickets, finalNumber } = roundDataAndUserTickets
    const ticketsWithRewardBrackets = userTickets.map((ticket) => {
      return {
        roundId,
        id: ticket.id,
        number: ticket.number,
        status: ticket.status,
        rewardBracket: this.getRewardBracketByNumber(ticket.number, finalNumber),
      }
    })

    // A rewardBracket of -1 means no matches. 0 and above means there has been a match
    const allWinningTickets = ticketsWithRewardBrackets.filter((ticket) => {
      return ticket.rewardBracket >= 0
    })
    // If ticket.status is true, the ticket has already been claimed
    const unclaimedWinningTickets = allWinningTickets.filter((ticket) => {
      return !ticket.status
    })
    
    if (unclaimedWinningTickets.length > 0) {
      var peakTotal = 0;
      var tickets = [];
      for (let i = 0; i < unclaimedWinningTickets.length; i++) {
        const { roundId, id, rewardBracket } = unclaimedWinningTickets[i]
        var rewardNum = await this.fetchRewardsByTicketId(roundId, id, rewardBracket);
        const reward = ethers.utils.formatEther(rewardNum.toString());
        peakTotal += parseFloat(reward);
        if(parseFloat(reward) != 0) {
          tickets.push(unclaimedWinningTickets[i]);
        }
      }

      return { ticketsWithUnclaimedRewards: tickets, allWinningTickets, peakTotal, roundId }
    }
  
    if (allWinningTickets.length > 0) {
      return { ticketsWithUnclaimedRewards: null, allWinningTickets, peakTotal: null, roundId }
    }
  
    return null
  }
  
  getWinningNumbersForRound (targetRoundId: string, lotteriesData: LotteryRoundGraphEntity[]) {
    const targetRound = lotteriesData.find((pastLottery) => pastLottery.id === targetRoundId)
    return targetRound?.finalNumber
  }

  async getDucks(account) {
    try {
      const data = JSON.stringify({
        "operationName": "GetUserOwnershipTokens",
        "variables": {
          "address": account.toLowerCase(),
          "collection": "0x8af3b4e513b7a68518cafd41f705c27d282977ae",
          "first": 100
        },
        "query": "query GetUserOwnershipTokens($collection: Address, $address: Address!, $first: Int, $after: Cursor, $last: Int, $before: Cursor) {\n  user(address: $address) {\n    ownerships(\n      collection: $collection\n      first: $first\n      after: $after\n      last: $last\n      before: $before\n    ) {\n      totalCount\n      pageInfo {\n        startCursor\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        __typename\n      }\n      edges {\n        node {\n          contract\n          tokenId\n          inEscrow\n          token {\n            contract\n            tokenId\n            name\n            description\n            image\n            imageThumb\n            likes\n            isLiked\n            hasBids\n            collection {\n              contract\n              name\n              __typename\n            }\n            listingPrice {\n              amount\n              payToken\n              __typename\n            }\n            auctionedPrice {\n              amount\n              payToken\n              __typename\n            }\n            auctionReservePrice {\n              amount\n              payToken\n              __typename\n            }\n            offeredPrice {\n              amount\n              payToken\n              __typename\n            }\n            lastTradePrice {\n              amount\n              payToken\n              __typename\n            }\n            auction {\n              endTime\n              __typename\n            }\n            chainID\n            __typename\n          }\n          chainID\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}\n"
      });
      
      const config = {
        method: 'post',
        url: 'https://nftmb.nftapparel.com.au/graphql',
        headers: { 
          'accept': '*/*', 
          'content-type': 'application/json', 
        },
        data : data
      };
      const resp = await axios(config).then(res => res.data);
      return resp.data.user.ownerships.edges;
    } catch (error) {
      console.log(error);
    }
    return [];
  }

  async getWrappedDucks(account) {
    const balance = await this.contracts.wNFT721.balanceOf(account);
    if(balance > 0) {
      let aggregatedCall = [];
      for(let i = 0 ; i < balance ; i ++) {
        aggregatedCall.push(
          this.contracts.wNFT721.tokenOfOwnerByIndex(account, i)
        );
      }
      const idList = await Promise.all(aggregatedCall);
      aggregatedCall = [];
      for(let i = 0 ; i < balance ; i ++) {
        aggregatedCall.push(
          this.contracts.WrappingBase.getWrappedToken(this.contracts.wNFT721.address, idList[i].toString())
        );
      }
      const resp = await Promise.all(aggregatedCall);
      aggregatedCall = [];
      for(let i = 0 ; i < balance ; i ++) {
        aggregatedCall.push(
          this.contracts.Masonry.getMultiplier(this.contracts.wNFT721.address, idList[i].toString())
        );
      }
      const multiplierList = await Promise.all(aggregatedCall);
      let result = [];
      for(let i = 0 ; i < balance ; i ++) {
        result.push({
          asset: resp[i].inAsset,
          wTokenId : idList[i],
          multiplier: multiplierList[i].toString()
        })
      }
      return result;
    }
    return [];
  }

  async getStakedDucks(account) {
    const response = await request(
      GRAPH_API_LOTTERY,
      gql`
        {
          ducks(where: {staker: "${account.toLowerCase()}"}) {
            tokenId
            staker
          }
        }
      `,
      {},
    )
    console.log(account.toLowerCase());
    console.log(response.ducks);
    let aggregatedCall = [];
    for(let i = 0 ; i < response.ducks.length ; i ++) {
      aggregatedCall.push(
        this.contracts.Masonry.getMultiplier(this.contracts.wNFT721.address, response.ducks[i].tokenId)
      );
    }
    const multiplierList = await Promise.all(aggregatedCall);
    aggregatedCall = [];
    for(let i = 0 ; i < response.ducks.length ; i ++) {
      aggregatedCall.push(
        this.contracts.WrappingBase.getWrappedToken(this.contracts.wNFT721.address, response.ducks[i].tokenId)
      );
    }
    const resp = await Promise.all(aggregatedCall);
    let result = [];
    for(let i = 0 ; i < response.ducks.length ; i ++) {
      result.push({
        asset: resp[i].inAsset,
        wTokenId : response.ducks[i].tokenId,
        multiplier: multiplierList[i].toString()
      })
    }
    return result;
  }

  async getBurntPRO() {
    const response = await request(
      GRAPH_API_LOTTERY,
      gql`
        {
          pshares(first: 1) {
            burnt
          }
        }
      `,
      {},
    )
    if(response.pshares.length > 0) {
      return ethers.utils.formatEther(response.pshares[0].burnt);
    }
    return "0";
  }
  
  async fetchUnclaimedUserRewards (
    account: string,
    userLotteryData: LotteryUserGraphEntity,
    lotteriesData: LotteryRoundGraphEntity[],
    currentLotteryId: string,
  ): Promise<LotteryTicketClaimData[]> {
    const { rounds } = userLotteryData
  
    // If there is no user round history - return an empty array
    if (rounds.length === 0) {
      return []
    }
  
    // If the web3 provider account doesn't equal the userLotteryData account, return an empty array - this is effectively a loading state as the user switches accounts
    if (userLotteryData.account.toLowerCase() !== account.toLowerCase()) {
      return []
    }
  
    // Filter out rounds without subgraph data (i.e. >100 rounds ago)
    const roundsInRange = rounds.filter((round) => {
      const lastCheckableRoundId = parseInt(currentLotteryId, 10) - MAX_LOTTERIES_REQUEST_SIZE
      const roundId = parseInt(round.lotteryId, 10)
      return roundId >= lastCheckableRoundId
    })
  
    // Filter out non-claimable rounds
    const claimableRounds = roundsInRange.filter((round) => {
      return round.status.toLowerCase() === LotteryStatus.CLAIMABLE
    })
  
    // Rounds with no tickets claimed OR rounds where a user has over 100 tickets, could have prizes
    const roundsWithPossibleWinnings = claimableRounds.filter((round) => {
      return !round.claimed || parseInt(round.totalTickets, 10) > 100
    })
  
    // Check the X  most recent rounds, where X is NUM_ROUNDS_TO_CHECK_FOR_REWARDS
    const roundsToCheck = roundsWithPossibleWinnings.slice(0, NUM_ROUNDS_TO_CHECK_FOR_REWARDS)
  
    if (roundsToCheck.length > 0) {
      const idsToCheck = roundsToCheck.map((round) => round.lotteryId)
      const userTicketData = await this.fetchUserTicketsForMultipleRounds(idsToCheck, account)
      const roundsWithTickets = userTicketData.filter((roundData) => roundData?.userTickets?.length > 0)
  
      const roundDataAndWinningTickets = roundsWithTickets.map((roundData) => {
        return { ...roundData, finalNumber: this.getWinningNumbersForRound(roundData.roundId, lotteriesData) }
      })
  
      const winningTicketsForPastRounds = await Promise.all(
        roundDataAndWinningTickets.map((roundData) => this.getWinningTickets(roundData)),
      )
      
      // Filter out null values (returned when no winning tickets found for past round)
      const roundsWithWinningTickets = winningTicketsForPastRounds.filter(
        (winningTicketData) => winningTicketData !== null,
      )
  
      // Filter to only rounds with unclaimed tickets
      const roundsWithUnclaimedWinningTickets = roundsWithWinningTickets.filter(
        (winningTicketData) => winningTicketData.ticketsWithUnclaimedRewards,
      )
  
      return roundsWithUnclaimedWinningTickets
    }
    // All rounds claimed, return empty array
    return []
  }


  //===================================================================
  //===================== GET ASSET STATS =============================
  //===================FROM NETSWAP TO DISPLAY =========================
  //=========================IN HOME PAGE==============================
  //===================================================================
  async getPeakStat(): Promise<TokenStat> {
    const { PeakMetisRewardPool, PeakMetisLPPeakRewardPool } = this.contracts;
    const supply = await this.PEAK.totalSupply();
    const peakRewardPoolSupply = await this.PEAK.balanceOf(PeakMetisRewardPool.address);
    const peakRewardPoolSupply2 = await this.PEAK.balanceOf(PeakMetisLPPeakRewardPool.address);
    const peakCirculatingSupply = supply
      .sub(peakRewardPoolSupply)
      .sub(peakRewardPoolSupply2);
    const priceInMETIS = await this.getTokenPriceFromPancakeswap(this.PEAK);
    const priceOfOneMETIS = await this.getWMETISPriceFromPancakeswap();
    const priceOfPeakInDollars = (Number(priceInMETIS) * Number(priceOfOneMETIS)).toFixed(2);

    return {
      tokenInMetis: priceInMETIS,
      priceInDollars: priceOfPeakInDollars,
      // tokenInMetis: "1.0025",
      // priceInDollars: "1.03",
      totalSupply: getDisplayBalance(supply, this.PEAK.decimal, 0),
      circulatingSupply: getDisplayBalance(peakCirculatingSupply, this.PEAK.decimal, 0),
    };
  }

  /**
   * Calculates various stats for the requested LP
   * @param name of the LP token to load stats for
   * @returns
   */
  async getLPStat(name: string): Promise<LPStat> {
    const lpToken = this.externalTokens[name];
    const lpTokenSupplyBN = await lpToken.totalSupply();
    const lpTokenSupply = getDisplayBalance(lpTokenSupplyBN, 18);
    const token0 = name.startsWith('PEAK') ? this.PEAK : this.PSHARE;
    const isPeak = name.startsWith('PEAK');
    const tokenAmountBN = await token0.balanceOf(lpToken.address);
    const tokenAmount = getDisplayBalance(tokenAmountBN, 18);

    const metisAmountBN = await this.METIS.balanceOf(lpToken.address);
    const metisAmount = getDisplayBalance(metisAmountBN, 18);
    const tokenAmountInOneLP = Number(tokenAmount) / Number(lpTokenSupply);
    const metisAmountInOneLP = Number(metisAmount) / Number(lpTokenSupply);
    const lpTokenPrice = await this.getLPTokenPrice(lpToken, token0, isPeak);
    const lpTokenPriceFixed = Number(lpTokenPrice).toFixed(2).toString();
    const liquidity = (Number(lpTokenSupply) * Number(lpTokenPrice)).toFixed(2).toString();
    return {
      tokenAmount: tokenAmountInOneLP.toFixed(2).toString(),
      metisAmount: metisAmountInOneLP.toFixed(2).toString(),
      priceOfOne: lpTokenPriceFixed,
      totalLiquidity: liquidity,
      totalSupply: Number(lpTokenSupply).toFixed(2).toString(),
    };
  }

  /**
   * Use this method to get price for Peak
   * @returns TokenStat for PBOND
   * priceInMETIS
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async gepBondStat(): Promise<TokenStat> {
    const { Treasury } = this.contracts;
    const peakStat = await this.getPeakStat();
    const bondPeakRatioBN = await Treasury.getBondPremiumRate();
    const modifier = bondPeakRatioBN / 1e18 > 1 ? bondPeakRatioBN / 1e18 : 1;
    const bondPriceInMETIS = (Number(peakStat.tokenInMetis) * modifier).toFixed(2);
    const priceOfPBondInDollars = (Number(peakStat.priceInDollars) * modifier).toFixed(2);
    const supply = await this.PBOND.displayedTotalSupply();
    return {
      tokenInMetis: bondPriceInMETIS,
      priceInDollars: priceOfPBondInDollars,
      totalSupply: supply,
      circulatingSupply: supply,
    };
  }

  /**
   * @returns TokenStat for PSHARE
   * priceInMETIS
   * priceInDollars
   * TotalSupply
   * CirculatingSupply (always equal to total supply for bonds)
   */
  async gepShareStat(): Promise<TokenStat> {
    const { PeakMetisLPPShareRewardPool } = this.contracts;

    const supply = await this.PSHARE.totalSupply();
    const priceInMETIS = await this.getTokenPriceFromPancakeswap(this.PSHARE);
    const peakRewardPoolSupply = await this.PSHARE.balanceOf(PeakMetisLPPShareRewardPool.address);
    const pShareCirculatingSupply = supply.sub(peakRewardPoolSupply);
    const priceOfOneMETIS = await this.getWMETISPriceFromPancakeswap();
    const priceOfSharesInDollars = (Number(priceInMETIS) * Number(priceOfOneMETIS)).toFixed(2);

    return {
      tokenInMetis: priceInMETIS,
      priceInDollars: priceOfSharesInDollars,
      totalSupply: getDisplayBalance(supply, this.PSHARE.decimal, 0),
      circulatingSupply: getDisplayBalance(pShareCirculatingSupply, this.PSHARE.decimal, 0),
    };
  }

  async getPeakStatInEstimatedTWAP(): Promise<TokenStat> {
    const { SeigniorageOracle, PeakMetisRewardPool } = this.contracts;
    const expectedPrice = await SeigniorageOracle.twap(this.PEAK.address, ethers.utils.parseEther('1'));

    const supply = await this.PEAK.totalSupply();
    const peakRewardPoolSupply = await this.PEAK.balanceOf(PeakMetisRewardPool.address);
    const peakCirculatingSupply = supply.sub(peakRewardPoolSupply);
    return {
      tokenInMetis: getDisplayBalance(expectedPrice),
      priceInDollars: getDisplayBalance(expectedPrice),
      totalSupply: getDisplayBalance(supply, this.PEAK.decimal, 0),
      circulatingSupply: getDisplayBalance(peakCirculatingSupply, this.PEAK.decimal, 0),
    };
  }

  async getPeakPriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getPeakUpdatedPrice();
  }

  async gepBondsPurchasable(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBurnablePeakLeft();
  }

  /**
   * Calculates the TVL, APR and daily APR of a provided pool/bank
   * @param bank
   * @returns
   */
  async getPoolAPRs(bank: Bank): Promise<PoolStats> {
    if (this.myAccount === undefined) return;
    const depositToken = bank.depositToken;
    const poolContract = this.contracts[bank.contract];
    const depositTokenPrice = await this.getDepositTokenPriceInDollars(bank.depositTokenName, depositToken);
    const stakeInPool = await depositToken.balanceOf(bank.address);
    const TVL = Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
    const stat = bank.earnTokenName === 'PEAK' ? await this.getPeakStat() : await this.gepShareStat();
    const tokenPerSecond = await this.getTokenPerSecond(
      bank.earnTokenName,
      bank.contract,
      poolContract,
      bank.depositTokenName,
    );

    const tokenPerHour = tokenPerSecond.mul(60).mul(60);
    const totalRewardPricePerYear =
      Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24).mul(365)));
    const totalRewardPricePerDay = Number(stat.priceInDollars) * Number(getDisplayBalance(tokenPerHour.mul(24)));
    const totalStakingTokenInPool =
      Number(depositTokenPrice) * Number(getDisplayBalance(stakeInPool, depositToken.decimal));
    const dailyAPR = (totalRewardPricePerDay / totalStakingTokenInPool) * 100;
    const yearlyAPR = (totalRewardPricePerYear / totalStakingTokenInPool) * 100;
    return {
      dailyAPR: dailyAPR.toFixed(2).toString(),
      yearlyAPR: yearlyAPR.toFixed(2).toString(),
      TVL: TVL.toFixed(2).toString(),
    };
  }

  /**
   * Method to return the amount of tokens the pool yields per second
   * @param earnTokenName the name of the token that the pool is earning
   * @param contractName the contract of the pool/bank
   * @param poolContract the actual contract of the pool
   * @returns
   */
  async getTokenPerSecond(
    earnTokenName: string,
    contractName: string,
    poolContract: Contract,
    depositTokenName: string,
  ) {
    if (earnTokenName === 'PEAK') {
      if (!contractName.endsWith('PeakRewardPool')) {
        const rewardPerSecond = await poolContract.peakPerSecond();
        return rewardPerSecond;
      }
      const poolStartTime = await poolContract.poolStartTime();
      const startDateTime = new Date(poolStartTime.toNumber() * 1000);
      const FOUR_DAYS = 4 * 24 * 60 * 60 * 1000;
      if (Date.now() - startDateTime.getTime() > FOUR_DAYS) {
        return await poolContract.epochPeakPerSecond(1);
      }
      return await poolContract.epochPeakPerSecond(0);
    }
    const rewardPerSecond = await poolContract.pSharePerSecond();
    if (depositTokenName.startsWith('PEAK')) {
      return rewardPerSecond.mul(35500).div(59500);
    } else {
      return rewardPerSecond.mul(24000).div(59500);
    }
  }

  /**
   * Method to calculate the tokenPrice of the deposited asset in a pool/bank
   * If the deposited token is an LP it will find the price of its pieces
   * @param tokenName
   * @param pool
   * @param token
   * @returns
   */
  async getDepositTokenPriceInDollars(tokenName: string, token: ERC20) {
    let tokenPrice;
    const priceOfOneMetisInDollars = await this.getWMETISPriceFromPancakeswap();
    if (tokenName === 'WMETIS') {
      tokenPrice = priceOfOneMetisInDollars;
    } else {
      if (tokenName === 'PEAK-METIS-LP') {
        tokenPrice = await this.getLPTokenPrice(token, this.PEAK, true);
      } else if (tokenName === 'PRO-METIS-LP') {
        tokenPrice = await this.getLPTokenPrice(token, this.PSHARE, false);
      }  else {
        tokenPrice = await this.getTokenPriceFromPancakeswap(token);
        tokenPrice = (Number(tokenPrice) * Number(priceOfOneMetisInDollars)).toString();
      }
    }
    return tokenPrice;
  }

  //===================================================================
  //===================== GET ASSET STATS =============================
  //=========================== END ===================================
  //===================================================================

  async getCurrentEpoch(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.epoch();
  }

  async gepBondOraclePriceInLastTWAP(): Promise<BigNumber> {
    const { Treasury } = this.contracts;
    return Treasury.getBondPremiumRate();
  }

  /**
   * Buy bonds with cash.
   * @param amount amount of cash to purchase bonds with.
   */
  async buyBonds(amount: string | number): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const treasuryPeakPrice = await Treasury.getPeakPrice();
    return await Treasury.buyBonds(decimalToBalance(amount), treasuryPeakPrice);
  }

  /**
   * Redeem bonds for cash.
   * @param amount amount of bonds to redeem.
   */
  async redeemBonds(amount: string): Promise<TransactionResponse> {
    const { Treasury } = this.contracts;
    const priceForPeak = await Treasury.getPeakPrice();
    return await Treasury.redeemBonds(decimalToBalance(amount), priceForPeak);
  }

  async getTotalValueLocked(): Promise<Number> {
    let totalValue = 0;
    for (const bankInfo of Object.values(bankDefinitions)) {
      const pool = this.contracts[bankInfo.contract];
      const token = this.externalTokens[bankInfo.depositTokenName];
      const tokenPrice = await this.getDepositTokenPriceInDollars(bankInfo.depositTokenName, token);
      const tokenAmountInPool = await token.balanceOf(pool.address);
      const value = Number(getDisplayBalance(tokenAmountInPool, token.decimal)) * Number(tokenPrice);
      const poolValue = Number.isNaN(value) ? 0 : value;
      totalValue += poolValue;
    }

    const PSHAREPrice = (await this.gepShareStat()).priceInDollars;
    const masonrypShareBalanceOf = await this.PSHARE.balanceOf(this.currentMasonry().address);
    const masonryTVL = Number(getDisplayBalance(masonrypShareBalanceOf, this.PSHARE.decimal)) * Number(PSHAREPrice);

    return totalValue;
  }

  /**
   * Calculates the price of an LP token
   * Reference https://github.com/DefiDebauchery/discordpricebot/blob/4da3cdb57016df108ad2d0bb0c91cd8dd5f9d834/pricebot/pricebot.py#L150
   * @param lpToken the token under calculation
   * @param token the token pair used as reference (the other one would be METIS in most cases)
   * @param isPeak sanity check for usage of peak token or pShare
   * @returns price of the LP token
   */
  async getLPTokenPrice(lpToken: ERC20, token: ERC20, isPeak: boolean): Promise<string> {
    const totalSupply = getFullDisplayBalance(await lpToken.totalSupply(), lpToken.decimal);
    //Get amount of tokenA
    const tokenSupply = getFullDisplayBalance(await token.balanceOf(lpToken.address), token.decimal);
    const stat = isPeak === true ? await this.getPeakStat() : await this.gepShareStat();
    const priceOfToken = stat.priceInDollars;
    const tokenInLP = Number(tokenSupply) / Number(totalSupply);
    const tokenPrice = (Number(priceOfToken) * tokenInLP * 2) //We multiply by 2 since half the price of the lp token is the price of each piece of the pair. So twice gives the total
      .toString();
    return tokenPrice;
  }

  async earnedFromBank(
    poolName: ContractName,
    earnTokenName: String,
    poolId: Number,
    account = this.myAccount,
  ): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      if (earnTokenName === 'PEAK') {
        return await pool.pendingPEAK(poolId, account);
      } else {
        return await pool.pendingShare(poolId, account);
      }
    } catch (err) {
      console.error(`Failed to call earned() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  async stakedBalanceOnBank(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<BigNumber> {
    const pool = this.contracts[poolName];
    try {
      let userInfo = await pool.userInfo(poolId, account);
      return await userInfo.amount;
    } catch (err) {
      console.error(`Failed to call balanceOf() on pool ${pool.address}: ${err.stack}`);
      return BigNumber.from(0);
    }
  }

  /**
   * Deposits token to given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async stake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.deposit(poolId, amount);
  }

  /**
   * Withdraws token from given pool.
   * @param poolName A name of pool contract.
   * @param amount Number of tokens with decimals applied. (e.g. 1.45 DAI * 10^18)
   * @returns {string} Transaction hash
   */
  async unstake(poolName: ContractName, poolId: Number, amount: BigNumber): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    return await pool.withdraw(poolId, amount);
  }

  /**
   * Transfers earned token reward from given pool to my account.
   */
  async harvest(poolName: ContractName, poolId: Number): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    //By passing 0 as the amount, we are asking the contract to only redeem the reward and not the currently staked token
    return await pool.withdraw(poolId, 0);
  }

  /**
   * Harvests and withdraws deposited tokens from the pool.
   */
  async exit(poolName: ContractName, poolId: Number, account = this.myAccount): Promise<TransactionResponse> {
    const pool = this.contracts[poolName];
    let userInfo = await pool.userInfo(poolId, account);
    return await pool.withdraw(poolId, userInfo.amount);
  }

  async fetchMasonryVersionOfUser(): Promise<string> {
    return 'latest';
  }

  currentMasonry(): Contract {
    if (!this.masonryVersionOfUser) {
      throw new Error('you must unlock the wallet to continue.');
    }
    return this.contracts.Masonry;
  }

  isOldMasonryMember(): boolean {
    return this.masonryVersionOfUser !== 'latest';
  }

  async getTokenPriceFromPancakeswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    // const { chainId } = this.config;
    const chainId = ChainId.MAINNET;
    const { WMETIS } = this.config.externalTokens;

    const wmetis = new Token(chainId, WMETIS[0], WMETIS[1], "WMETIS");
    const token = new Token(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const wmetisToToken = await Fetcher.fetchPairData(wmetis, token, this.provider);
      const priceInBUSD = new Route([wmetisToToken], token);
      return priceInBUSD.midPrice.toFixed(4);
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getTokenPriceFromSpiritswap(tokenContract: ERC20): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { chainId } = this.config;

    const { WMETIS } = this.externalTokens;

    const wmetis = new TokenSpirit(chainId, WMETIS.address, WMETIS.decimal);
    const token = new TokenSpirit(chainId, tokenContract.address, tokenContract.decimal, tokenContract.symbol);
    try {
      const wmetisToToken = await FetcherSpirit.fetchPairData(wmetis, token, this.provider);
      const liquidityToken = wmetisToToken.liquidityToken;
      let metisBalanceInLP = await WMETIS.balanceOf(liquidityToken.address);
      let metisAmount = Number(getFullDisplayBalance(metisBalanceInLP, WMETIS.decimal));
      let shibaBalanceInLP = await tokenContract.balanceOf(liquidityToken.address);
      let shibaAmount = Number(getFullDisplayBalance(shibaBalanceInLP, tokenContract.decimal));
      const priceOfOneMetisInDollars = await this.getWMETISPriceFromPancakeswap();
      let priceOfShiba = (metisAmount / shibaAmount) * Number(priceOfOneMetisInDollars);
      return priceOfShiba.toString();
    } catch (err) {
      console.error(`Failed to fetch token price of ${tokenContract.symbol}: ${err}`);
    }
  }

  async getWMETISPriceFromPancakeswap(): Promise<string> {
    const ready = await this.provider.ready;
    if (!ready) return;
    const { WMETIS, USDT } = this.externalTokens;
    try {
      const usdt_wmetis_lp_pair = this.externalTokens['USDT-METIS-LP'];
      let metis_amount_BN = await WMETIS.balanceOf(usdt_wmetis_lp_pair.address);
      let metis_amount = Number(getFullDisplayBalance(metis_amount_BN, WMETIS.decimal));
      let usdt_amount_BN = await USDT.balanceOf(usdt_wmetis_lp_pair.address);
      let usdt_amount = Number(getFullDisplayBalance(usdt_amount_BN, USDT.decimal));
      return (usdt_amount / metis_amount).toString();
    } catch (err) {
      console.error(`Failed to fetch token price of WMETIS: ${err}`);
    }
  }

  //===================================================================
  //===================================================================
  //===================== MASONRY METHODS =============================
  //===================================================================
  //===================================================================

  async getMasonryAPR() {
    const Masonry = this.currentMasonry();
    const latestSnapshotIndex = await Masonry.latestSnapshotIndex();
    const lastHistory = await Masonry.masonryHistory(latestSnapshotIndex);

    const lastRewardsReceived = lastHistory[1];

    const PSHAREPrice = (await this.gepShareStat()).priceInDollars;
    const PEAKPrice = (await this.getPeakStat()).priceInDollars;
    const epochRewardsPerShare = lastRewardsReceived / 1e18;

    //Mgod formula
    const amountOfRewardsPerDay = epochRewardsPerShare * Number(PEAKPrice) * 4;
    const masonrypShareBalanceOf = await this.PSHARE.balanceOf(Masonry.address);
    const masonryTVL = Number(getDisplayBalance(masonrypShareBalanceOf, this.PSHARE.decimal)) * Number(PSHAREPrice);
    const realAPR = ((amountOfRewardsPerDay * 100) / masonryTVL) * 365;
    return realAPR;
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserClaimRewardFromMasonry(): Promise<boolean> {
    const Masonry = this.currentMasonry();
    return await Masonry.canClaimReward(this.myAccount);
  }

  /**
   * Checks if the user is allowed to retrieve their reward from the Masonry
   * @returns true if user can withdraw reward, false if they can't
   */
  async canUserUnstakeFromMasonry(): Promise<boolean> {
    const Masonry = this.currentMasonry();
    const canWithdraw = await Masonry.canWithdraw(this.myAccount);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    const notStaked = Number(getDisplayBalance(stakedAmount, this.PSHARE.decimal)) === 0;
    const result = notStaked ? true : canWithdraw;
    return result;
  }

  async timeUntilClaimRewardFromMasonry(): Promise<BigNumber> {
    // const Masonry = this.currentMasonry();
    // const mason = await Masonry.masons(this.myAccount);
    return BigNumber.from(0);
  }

  async getTotalStakedInMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    return await Masonry.totalSupply();
  }

  async stakeShareToMasonry(amount: string): Promise<TransactionResponse> {
    if (this.isOldMasonryMember()) {
      throw new Error("you're using old masonry. please withdraw and deposit the PSHARE again.");
    }
    const Masonry = this.currentMasonry();
    return await Masonry.stake(decimalToBalance(amount));
  }

  async stakeDuckToMasonry(tokenId: string): Promise<TransactionResponse> {
    if (this.isOldMasonryMember()) {
      throw new Error("you're using old masonry. please withdraw and deposit the PSHARE again.");
    }
    const Masonry = this.currentMasonry();
    return await Masonry.stakeDuck(this.contracts.wNFT721.address, tokenId);
  }

  async withdrawDuckFromMasonry(tokenId: string): Promise<TransactionResponse> {
    if (this.isOldMasonryMember()) {
      throw new Error("you're using old masonry. please withdraw and deposit the PSHARE again.");
    }
    const Masonry = this.currentMasonry();
    return await Masonry.withdrawDuck(this.contracts.wNFT721.address, tokenId);
  }
  
  async getStakedSharesOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === 'v1') {
      return await Masonry.gepShareOf(this.myAccount);
    }
    return await Masonry.balanceOf(this.myAccount);
  }

  async getStakedDuckOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    const duckInfo = await Masonry.stakeInfo(this.myAccount);
    return duckInfo.balance;
  }

  async getEarningsOnMasonry(): Promise<BigNumber> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === 'v1') {
      return await Masonry.getCashEarningsOf(this.myAccount);
    }
    return await Masonry.earned(this.myAccount);
  }

  async withdrawShareFromMasonry(amount: string): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.withdraw(decimalToBalance(amount));
  }

  async harvestCashFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    if (this.masonryVersionOfUser === 'v1') {
      return await Masonry.claimDividends();
    }
    return await Masonry.claimReward();
  }

  async exitFromMasonry(): Promise<TransactionResponse> {
    const Masonry = this.currentMasonry();
    return await Masonry.exit();
  }

  async getTreasuryNextAllocationTime(): Promise<AllocationTime> {
    const { Treasury } = this.contracts;
    const nextEpochTimestamp: BigNumber = await Treasury.nextEpochPoint();
    const nextAllocation = new Date(nextEpochTimestamp.mul(1000).toNumber());
    const prevAllocation = new Date(Date.now());

    return { from: prevAllocation, to: nextAllocation };
  }
  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to claim
   * their reward from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserClaimRewardTime(): Promise<AllocationTime> {
    const { Masonry, Treasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint(); //in unix timestamp
    const currentEpoch = await Masonry.epoch();
    const mason = await Masonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const periodInHours = period / 60 / 60; // 6 hours, period is displayed in seconds which is 21600
    const rewardLockupEpochs = await Masonry.rewardLockupEpochs();
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(rewardLockupEpochs);

    const fromDate = new Date(Date.now());
    if (targetEpochForClaimUnlock - currentEpoch <= 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - currentEpoch - 1;
      const endDate = moment(toDate)
        .add(delta * periodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  /**
   * This method calculates and returns in a from to to format
   * the period the user needs to wait before being allowed to unstake
   * from the masonry
   * @returns Promise<AllocationTime>
   */
  async getUserUnstakeTime(): Promise<AllocationTime> {
    const { Masonry, Treasury } = this.contracts;
    const nextEpochTimestamp = await Masonry.nextEpochPoint();
    const currentEpoch = await Masonry.epoch();
    const mason = await Masonry.masons(this.myAccount);
    const startTimeEpoch = mason.epochTimerStart;
    const period = await Treasury.PERIOD();
    const PeriodInHours = period / 60 / 60;
    const withdrawLockupEpochs = await Masonry.withdrawLockupEpochs();
    const fromDate = new Date(Date.now());
    const targetEpochForClaimUnlock = Number(startTimeEpoch) + Number(withdrawLockupEpochs);
    const stakedAmount = await this.getStakedSharesOnMasonry();
    if (currentEpoch <= targetEpochForClaimUnlock && Number(stakedAmount) === 0) {
      return { from: fromDate, to: fromDate };
    } else if (targetEpochForClaimUnlock - currentEpoch === 1) {
      const toDate = new Date(nextEpochTimestamp * 1000);
      return { from: fromDate, to: toDate };
    } else {
      const toDate = new Date(nextEpochTimestamp * 1000);
      const delta = targetEpochForClaimUnlock - Number(currentEpoch) - 1;
      const endDate = moment(toDate)
        .add(delta * PeriodInHours, 'hours')
        .toDate();
      return { from: fromDate, to: endDate };
    }
  }

  async watchAssetInMetamask(assetName: string): Promise<boolean> {
    const { ethereum } = window as any;
    if (ethereum && ethereum.networkVersion === config.chainId.toString()) {
      let asset;
      let assetUrl;
      if (assetName === 'PEAK') {
        asset = this.PEAK;
        assetUrl = 'https://peak.finance/presskit/peak_icon_noBG.png';
      } else if (assetName === 'PRO') {
        asset = this.PSHARE;
        assetUrl = 'https://peak.finance/presskit/pshare_icon_noBG.png';
      } else if (assetName === 'POND') {
        asset = this.PBOND;
        assetUrl = 'https://peak.finance/presskit/pbond_icon_noBG.png';
      }
      await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: asset.address,
            symbol: asset.symbol,
            decimals: 18,
            image: assetUrl,
          },
        },
      });
    }
    return true;
  }

  async providePeakMetisLP(metisAmount: string, peakAmount: BigNumber): Promise<TransactionResponse> {
    const { TaxOffice } = this.contracts;
    let overrides = {
      value: parseUnits(metisAmount, 18),
    };
    return await TaxOffice.addLiquidityMetisTaxFree(peakAmount, peakAmount.mul(992).div(1000), parseUnits(metisAmount, 18).mul(992).div(1000), overrides);
  }

  async provideProMetisLP(metisAmount: string, proAmount: BigNumber): Promise<TransactionResponse> {
    const { TaxOffice } = this.contracts;
    let overrides = {
      value: parseUnits(metisAmount, 18),
    };
    return await TaxOffice.addProLiquidityMetisTaxFree(proAmount, proAmount.mul(992).div(1000), parseUnits(metisAmount, 18).mul(992).div(1000), overrides);
  }

  async wrapNFT(tokenContract: string, tokenAmount: BigNumber, nftContract: string, tokenId: number, date: string): Promise<TransactionResponse> {
    const { WrappingBase } = this.contracts;
    const receiver = await this.signer.getAddress();
    const inData = {
      inAsset: {
        asset : {
          assetType: 3,
          contractAddress: nftContract
        },
        tokenId: tokenId,
        amount: 1
      },
      outType: 3,
      lockPeriod: Math.floor(new Date(date).getTime() / 1000) - Math.floor(Date.now()/1000),
      outBalance: 1
    };
    const collateral = {
      asset : {
        assetType: 2,
        contractAddress: tokenContract
      },
      tokenId: 0,
      amount: tokenAmount
    };
    return await WrappingBase.wrap(inData , collateral, receiver);
  }

  async unWrapNFT(tokenId: string): Promise<TransactionResponse> {
    const { WrappingBase } = this.contracts;
    return await WrappingBase.unWrap(this.contracts.wNFT721.address, tokenId);
  }

  async quoteFromNetSwap(tokenAmount: string, tokenName: string): Promise<string> {
    const { UniswapV2Router } = this.contracts;
    const { _reserve0, _reserve1 } = await this.PEAKWMETIS_LP.getReserves();
    let quote = 0;
    if(parseUnits(tokenAmount).toString() != "0") {
      if (tokenName === 'METIS') {
        quote = await UniswapV2Router.quote(parseUnits(tokenAmount), _reserve1, _reserve0);
      } else {
        quote = await UniswapV2Router.quote(parseUnits(tokenAmount), _reserve0, _reserve1);
      }
      return (quote / 1e18).toString();
    }
  }

  async quoteProFromNetSwap(tokenAmount: string, tokenName: string): Promise<string> {
    const { UniswapV2Router } = this.contracts;
    const { _reserve0, _reserve1 } = await this.PROMETIS_LP.getReserves();
    let quote = 0;
    if(parseUnits(tokenAmount).toString() != "0") {
      if (tokenName === 'METIS') {
        quote = await UniswapV2Router.quote(parseUnits(tokenAmount), _reserve1, _reserve0);
      } else {
        quote = await UniswapV2Router.quote(parseUnits(tokenAmount), _reserve0, _reserve1);
      }
      return (quote / 1e18).toString();
    }
  }

  /**
   * @returns an array of the regulation events till the most up to date epoch
   */
  async listenForRegulationsEvents(): Promise<any> {
    const { Treasury } = this.contracts;

    const treasuryDaoFundedFilter = Treasury.filters.DaoFundFunded();
    const treasuryDevFundedFilter = Treasury.filters.DevFundFunded();
    const treasuryMasonryFundedFilter = Treasury.filters.MasonryFunded();
    const boughpBondsFilter = Treasury.filters.BoughpBonds();
    const redeemBondsFilter = Treasury.filters.RedeemedBonds();

    let epochBlocksRanges: any[] = [];
    let masonryFundEvents = await Treasury.queryFilter(treasuryMasonryFundedFilter);
    var events: any[] = [];
    masonryFundEvents.forEach(function callback(value, index) {
      events.push({ epoch: index + 1 });
      events[index].masonryFund = getDisplayBalance(value.args[1]);
      if (index === 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
      }
      if (index > 0) {
        epochBlocksRanges.push({
          index: index,
          startBlock: value.blockNumber,
          boughBonds: 0,
          redeemedBonds: 0,
        });
        epochBlocksRanges[index - 1].endBlock = value.blockNumber;
      }
    });

    epochBlocksRanges.forEach(async (value, index) => {
      events[index].bondsBought = await this.gepBondsWithFilterForPeriod(
        boughpBondsFilter,
        value.startBlock,
        value.endBlock,
      );
      events[index].bondsRedeemed = await this.gepBondsWithFilterForPeriod(
        redeemBondsFilter,
        value.startBlock,
        value.endBlock,
      );
    });
    let DEVFundEvents = await Treasury.queryFilter(treasuryDevFundedFilter);
    DEVFundEvents.forEach(function callback(value, index) {
      events[index].devFund = getDisplayBalance(value.args[1]);
    });
    let DAOFundEvents = await Treasury.queryFilter(treasuryDaoFundedFilter);
    DAOFundEvents.forEach(function callback(value, index) {
      events[index].daoFund = getDisplayBalance(value.args[1]);
    });
    return events;
  }

  /**
   * Helper method
   * @param filter applied on the query to the treasury events
   * @param from block number
   * @param to block number
   * @returns the amount of bonds events emitted based on the filter provided during a specific period
   */
  async gepBondsWithFilterForPeriod(filter: EventFilter, from: number, to: number): Promise<number> {
    const { Treasury } = this.contracts;
    const bondsAmount = await Treasury.queryFilter(filter, from, to);
    return bondsAmount.length;
  }

  async estimateZapIn(tokenName: string, lpName: string, amount: string): Promise<number[]> {
    const { zapper } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    let estimate;
    if (tokenName === METIS_TICKER) {
      estimate = await zapper.estimateZapIn(lpToken.address, NETSWAP_ROUTER_ADDR, parseUnits(amount, 18));
    } else {
      const token = tokenName === PEAK_TICKER ? this.PEAK : this.PSHARE;
      estimate = await zapper.estimateZapInToken(
        token.address,
        lpToken.address,
        NETSWAP_ROUTER_ADDR,
        parseUnits(amount, 18),
      );
    }
    return [estimate[0] / 1e18, estimate[1] / 1e18];
  }
  async zapIn(tokenName: string, lpName: string, amount: string): Promise<TransactionResponse> {
    const { zapper } = this.contracts;
    const lpToken = this.externalTokens[lpName];
    if (tokenName === METIS_TICKER) {
      let overrides = {
        value: parseUnits(amount, 18),
      };
      return await zapper.zapIn(lpToken.address, NETSWAP_ROUTER_ADDR, this.myAccount, overrides);
    } else {
      const token = tokenName === PEAK_TICKER ? this.PEAK : this.PSHARE;
      return await zapper.zapInToken(
        token.address,
        parseUnits(amount, 18),
        lpToken.address,
        NETSWAP_ROUTER_ADDR,
        this.myAccount,
      );
    }
  }
  async swapPBondToPShare(pbondAmount: BigNumber): Promise<TransactionResponse> {
    const { PShareSwapper } = this.contracts;
    return await PShareSwapper.swapPBondToPShare(pbondAmount);
  }
  async estimateAmountOfPShare(pbondAmount: string): Promise<string> {
    const { PShareSwapper } = this.contracts;
    try {
      const estimateBN = await PShareSwapper.estimateAmountOfPShare(parseUnits(pbondAmount, 18));
      return getDisplayBalance(estimateBN, 18, 6);
    } catch (err) {
      console.error(`Failed to fetch estimate pshare amount: ${err}`);
    }
  }

  async getPShareSwapperStat(address: string): Promise<PShareSwapperStat> {
    const { PShareSwapper } = this.contracts;
    const pshareBalanceBN = await PShareSwapper.getPShareBalance();
    const pbondBalanceBN = await PShareSwapper.getPBondBalance(address);
    // const peakPriceBN = await PShareSwapper.getPeakPrice();
    // const psharePriceBN = await PShareSwapper.getPSharePrice();
    const ratePSharePerPeakBN = await PShareSwapper.getPShareAmountPerPeak();
    const pshareBalance = getDisplayBalance(pshareBalanceBN, 18, 5);
    const pbondBalance = getDisplayBalance(pbondBalanceBN, 18, 5);
    return {
      pshareBalance: pshareBalance.toString(),
      pbondBalance: pbondBalance.toString(),
      // peakPrice: peakPriceBN.toString(),
      // psharePrice: psharePriceBN.toString(),
      ratePSharePerPeak: ratePSharePerPeakBN.toString(),
    };
  }
}
