import { BigNumber } from 'ethers';

export const DECIMALS_18 = BigNumber.from(10).pow(18);

export const BOND_REDEEM_PRICE = 1.01;
export const BOND_REDEEM_PRICE_BN = DECIMALS_18.mul(101).div(100);

export const TICKET_LIMIT_PER_REQUEST = 2500
export const NUM_ROUNDS_TO_CHECK_FOR_REWARDS = 3
export const NUM_ROUNDS_TO_FETCH_FROM_NODES = 3

export const MAX_USER_LOTTERIES_REQUEST_SIZE = 100
export const MAX_LOTTERIES_REQUEST_SIZE = 100
export const GRAPH_API_LOTTERY = "https://graph.peakfinance.io/subgraphs/name/muranox/wrapping"
// export const GRAPH_API_LOTTERY = "https://andromeda-graph.metis.io/subgraphs/name/muranox/lottery"
